import React from "react";
import ThemeContext from "../../contexts/Theme/ThemeContext";
import Moon from "./Moon";
import Sun from "./Sun";

import * as styles from "./style.module.scss";

const DarkModeToggle = () => {
    const { dark, toggle } = React.useContext(ThemeContext);

    return (
        <div className={styles.container}>
            <Sun />
            <label className={styles.toggle}>
                <input
                    type="checkbox"
                    checked={dark}
                    onChange={() => toggle(dark ? "light" : "dark")}
                />
                <span className={styles.slider}> </span>
            </label>
            <Moon />
        </div>
    );
};

export default DarkModeToggle;
