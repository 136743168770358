import * as React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';


export default function SEO({ lang = 'en', title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            image
            author
          }
        }
      }
    `
  );

  return (
    <Helmet

      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: site.siteMetadata.description
        },
        { property: 'og:title', content: title },
        {
          property: 'og:description',
          content: site.siteMetadata.description
        },
        { property: 'og:type', content: 'website' },

        { property: 'og:image', content: `${site.siteMetadata.siteUrl}${site.siteMetadata.image}`},

      ]}
    />
  );
}