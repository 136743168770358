import * as React from "react";

import * as styles from "./style.module.scss";

import { GithubIcon } from "../Icons/GithubIcon";
import { LinkedInIcon } from "../Icons/LinkedInIcon";

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <ul>
                <li>
                    <a href="https://github.com/sagar-13/">
                        <GithubIcon />
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/in/suri-sagar/">
                        <LinkedInIcon />
                    </a>
                </li>
            </ul>
        </footer>
    );
};

export default Footer;
