import React, { useCallback } from "react";
import { useInView } from "react-intersection-observer";
import * as styles from "./style.module.scss";

const Animate = ({ children, stagger, direction }) => {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0.1,
        delay: 100,
        triggerOnce: true,
    });

    const transitionMaker = useCallback(() => {


        if (direction === "up") {
            return styles.up
        } else if (direction === "down") {
            return styles.down
        } else if (direction === "right") {
            return styles.right
        } else if (direction === "left") {
            return styles.left
        } else {
            return styles.up
        }
    }, [direction]);

    return (
        <div
            ref={ref}
            className={`${inView ? styles.animate : styles.preAnimate} ${
                styles.transition
            } ${!inView ? transitionMaker(): ''}`}
            // style={{
            //     // transitionDelay: `${stagger ? stagger * 0.1 : 0}s`,
            //     transform: !inView ? transitionMaker() : "",
            // }}
        >
            {children}
        </div>
    );
};

export default Animate;
