import React, { useState, useLayoutEffect } from "react";
import ThemeContext from "./ThemeContext";

export function ThemeProvider(props) {
    const isBrowser = typeof window !== "undefined";

    // keeps state of the current chosen theme

    const [dark, setDark] = useState(
        isBrowser ? window.localStorage.getItem("theme") : "dark"
    );

    // paints the app before it renders elements
    useLayoutEffect(() => {
        const lastTheme = window.localStorage.getItem("theme");

        if (lastTheme === "light") {
            setDark(false);
            applyTheme("light");
        }

        if (!lastTheme || lastTheme === "dark") {
            setDark(true);
            applyTheme("dark");
        }
    }, [dark]);

    const applyTheme = (theme) => {
        let htmlElement = document.documentElement;
        htmlElement.setAttribute("theme", theme);
    };

    const toggle = (theme) => {
        setDark(!dark);
        const isBrowser = typeof window !== "undefined";

        if (isBrowser) window.localStorage.setItem("theme", theme);
    };

    return (
        <ThemeContext.Provider value={{ dark, toggle }}>
            {props.children}
        </ThemeContext.Provider>
    );
}
