import React, { useEffect, useState, useRef } from "react";
import * as styles from "./style.module.scss";
import * as transitions from "./transitions.module.scss";
import useMountTransition from "../../hooks/useMountTransition";
import DarkModeToggle from "../DarkModeToggle/DarkModeToggle";
import Footer from "../Footer/Footer";

import { navigate } from "gatsby";
const debounce = (func, wait, immediate) => {
    var timeout;
    return function () {
        var context = this,
            args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

const Nav = () => {
    const [viewVisible, setViewVisible] = useState(false);
    const hasTransitionedIn = useMountTransition(viewVisible, 500);
    const prevScrollPos = useRef(0);
    const [visible, setVisible] = useState(true);
    const isBrowser = typeof window !== "undefined";
    const handleScroll = debounce(() => {
        const currentScrollPos = isBrowser ? window.pageYOffset : 0;

        setVisible(
            (prevScrollPos.current > currentScrollPos &&
                prevScrollPos.current - currentScrollPos > 50) ||
                currentScrollPos < 10 ||
                viewVisible
        );

        prevScrollPos.current = currentScrollPos;
    }, 100);

    const closeView = () => {
        setViewVisible(false);
    };

    useEffect(() => {
        isBrowser && window.addEventListener("scroll", handleScroll);

        return () =>
            isBrowser && window.removeEventListener("scroll", handleScroll);
    }, [handleScroll, isBrowser]);

    return (
        <>
            <nav
                className={styles.nav}
                style={{
                    transform: visible
                        ? `translateY(0px)`
                        : `translateY(-60px)`,
                }}
                // style={{ transform: visible ? "0" : "-60px" }}
            >
                <div> {viewVisible && <DarkModeToggle />} </div>
                {/* <div>
                    <a href="mailto:sagar.suri@mail.utoronto.ca">
                        <button>
                            <h3>Contact</h3>
                        </button>
                    </a>
                </div> */}
                <div>
                    <button
                        className={styles.menuButton}
                        onClick={() => setViewVisible(!viewVisible)}
                    >
                        <div
                            className={`${styles.navIcon} ${
                                viewVisible ? styles.open : ""
                            }`}
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </button>
                </div>
            </nav>
            
          {(hasTransitionedIn || viewVisible) && <NavView hasTransitionedIn={hasTransitionedIn} 
                                        viewVisible={viewVisible} close={closeView} />
        
        }
        </>
    );
};

const NavView = ({ close, viewVisible, hasTransitionedIn }) => {
    const urls = {
        "Projects": "/#projects",
        "Archive": "/archive/",
        "Work": "/#work",
        "Education": "/#education",
    };
    return (
                    <div className={[styles.navContainer, (hasTransitionedIn && viewVisible) ? styles.visibleBg: ''].join(' ')}>
                    <div className={[styles.navView, (hasTransitionedIn && viewVisible) ? styles.visible: ''].join(' ')}
                    
                    >
                        {["Projects", "Archive", "Work", "Education"].map(
                            (section) => {
                                return (
                                    <div
                                        key={section}
                                        onClick={() => {
                                            close();
                                            navigate(urls[section]);
                                        }}
                                    >
                                        <h4>{section}</h4>
                                    </div>
                                );
                            }
                        )}

                        <Footer />
                    </div>
                    </div>
    )
                        }

export default Nav;